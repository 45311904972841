import React, { useState, useEffect } from 'react';
import Logos from '../logos/Logos';
import s from './Brief.module.scss';
import cn from 'classnames';

interface CountdownPropsType {
    scrollPos: number;
    isParallax: boolean;
    inView: boolean;
    targetDate: number;
    onExpire: () => void; // Function type for onExpire
}

const Countdown = ({
    inView,
    scrollPos,
    isParallax,
    onExpire,
    targetDate
}: CountdownPropsType) => {
    const isMobile = window.innerWidth < 601;
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    function calculateTimeLeft() {
        const now = new Date().getTime();
        const difference = targetDate - now;

        if (difference <= 0) {
            return null; // Timer expired
        }

        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor(
                (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            ),
            minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((difference % (1000 * 60)) / 1000)
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            const newTimeLeft = calculateTimeLeft();
            if (!newTimeLeft) {
                clearInterval(timer);
                onExpire(); // Notify parent component that the timer has expired
            } else {
                setTimeLeft(newTimeLeft);
            }
        }, 1000);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, [onExpire]);

    if (!timeLeft) {
        return null; // Prevent rendering when the timer has expired
    }

    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageMain)}>
                <div className={cn(s.homeHeroWrapper, s.homeCountdownWrapper)}>
                    <div className={s.logosWrapper}>
                        {isMobile ? (
                            <img
                                src='/media/2025/Pre_Title_FL_20th_Mobile.svg'
                                alt={'Future Lions Logo Mobile'}
                            />
                        ) : (
                            <img
                                src='/media/2025/Pre_Title_FL_20th_Desktop.svg'
                                alt={'Future Lions Logo'}
                            />
                        )}
                        {/* <Logos pageId={99} /> */}
                        <div className={s.countdown}>
                            <div className={s.countdownItem}>
                                <div>{timeLeft.days}</div>
                                <p>DAYS</p>
                            </div>
                            <div className={s.separator}>
                                <span>:</span>
                            </div>
                            <div className={s.countdownItem}>
                                <div>{timeLeft.hours}</div>
                                <p>HOURS</p>
                            </div>
                            <div className={s.separator}>
                                <span>:</span>
                            </div>
                            <div className={s.countdownItem}>
                                <div>{timeLeft.minutes}</div>
                                <p>MINUTES</p>
                            </div>
                            <div className={s.separator}>
                                <span>:</span>
                            </div>
                            <div className={s.countdownItem}>
                                <div>{timeLeft.seconds}</div>
                                <p>SECONDS</p>
                            </div>
                        </div>
                        <div className={s.preTitleText}>
                            <img
                                src='/assets/text-icons.svg'
                                alt='prepare to find your lionheart'
                            />
                        </div>
                    </div>
                    <div className={s.logosCountdown}>
                        <Logos pageId={0} countdown={true} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Countdown;
