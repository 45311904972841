import React from 'react';

export default [
    <div className='faq-panel'>
        <div className='faq-copy'>
            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Can I enter more <span className="nowrap">than once?</span>
                </div>
                <div className='faq-panel-desc'>
                    Yes. Remember quality <span className="nowrap">beats quantity.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    How should my team be <span className="nowrap">set up?</span>
                </div>
                <div className='faq-panel-desc'>
                    Up to you. We ask for 5 team <span className="nowrap">members maximum.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>Can I amend my entry?</div>
                <div className='faq-panel-desc'>
                    Yes. Email your details and entry code <span className='nowrap'>to{' '}
                        <a
                            className='faq-panel-title-link'
                            href='mailto:futurelions@akqa.com'
                        >
                            futurelions@akqa.com
                        </a>
                        .</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>Is there an <span className="nowrap">entry fee?</span></div>
                <div className='faq-panel-desc'>
                    No, you’re starting out, keep <span className="nowrap">your money.</span>
                </div>
            </div>
        </div>
        <div className='faq-image'></div>
    </div>,
    <div className='faq-panel'>
        <div className='faq-copy'>
            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Should I enter a complete campaign or <span className="nowrap">a single execution?</span>
                </div>
                <div className='faq-panel-desc'>
                    Your choice. Just make sure your idea answers <span className="nowrap">the brief.</span>
                </div>
            </div>
            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Is there a preference for <span className="nowrap">presentation type?</span>
                </div>
                <div className='faq-panel-desc'>
                    Not necessarily. Just try to convey your idea as clearly <span className="nowrap">as possible.</span>
                </div>
            </div>
        </div>
        <div className='faq-image'></div>
    </div>,

    <div className='faq-panel'>
        <div className='faq-copy'>
            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Does my work have to be <span className="nowrap">in English?</span>
                </div>
                <div className='faq-panel-desc'>
                    No. But a clear English translation must be provided if it’s
                    in <span className="nowrap">another language.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Does my presentation have to be in  <span className="nowrap">a digital format?</span>
                </div>
                <div className='faq-panel-desc'>
                    Yes. Entries must be a link to a PDF, video, slideshow,
                    presentation <span className="nowrap">or website.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Does my concept have to <span className="nowrap">be digital?</span>
                </div>
                <div className='faq-panel-desc'>
                    No. We’re just looking for <span className="nowrap">innovative ideas.</span>
                </div>
            </div>
        </div>
        <div className='faq-image'></div>
    </div>,

    <div className='faq-panel'>
        <div className='faq-copy'>
            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Do I have to be enrolled in an advertising programme to <span className="nowrap">be
                        eligible?</span>
                </div>
                <div className='faq-panel-desc'>
                    No. You just need an incredible idea and a hunger <span className="nowrap">for
                        innovation.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Am I eligible to enter if <span className="nowrap">I’m working?</span>
                </div>
                <div className='faq-panel-desc'>
                    Yes. As long as you have less than 6 months <span className="nowrap">industry
                        experience.</span>
                </div>
            </div>

            <div className='faq-panel-paragraph'>
                <div className='faq-panel-title'>
                    Is there a minimum <span className="nowrap">age requirement?</span>
                </div>
                <div className='faq-panel-desc'>
                    Yes. You must be aged between 18 and 25 years old on 16 June 2025. If you are older than 25 you must be enrolled on a full-time education course <span className="nowrap">on 16 June 2025.</span>
                </div>
            </div>
        </div>
        <div className='faq-image'></div>
    </div>
];
