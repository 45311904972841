import React, { useEffect } from 'react';
import s from './Brief.module.scss';
import { Icons } from '../icons/Icons';
import cn from 'classnames';

// import FrameWebp from '../../assets/2023/milestone/Frame_Clean.webp';
// import FramePng from '../../assets/2023/milestone/Frame_Clean.png';
// import KeyDatePeopleWebp from '../../assets/2023/milestone/KeyDate_People_Clean.webp';
// import KeyDatePeoplePng from '../../assets/2023/milestone/KeyDate_People_Clean.png';
// import RatLottie from '../../assets/2023/milestone/rat-lottie.json';
// import {Player} from "@lottiefiles/react-lottie-player";

interface MilestonePropsType {
    inView: boolean
}

const Milestone = ({ inView }: MilestonePropsType) => {
    const lottiePlayerRef = React.createRef();

    useEffect(() => {
        if (inView && lottiePlayerRef.current) {
            lottiePlayerRef.current.play();
        }
    }, [inView, lottiePlayerRef]);

    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageMilestone)}>
                <div className={s.milestoneContainer}>
                    <div className={s.descriptionWrapper}>
                        <div className={s.header}>
                            <div className={s.headerText}>KEY DATES</div>
                        </div>

                        {/* <div className={s.descriptionText}>
                        <p>
                        Four winning teams will be selected to present their ideas live to Volvo during the Cannes Lions International Festival of Creativity. One team will be awarded the Future Lions Grand Prix and get the chance to <span className='nowrap'>turn the idea into reality.</span>
                        </p>
                    </div> */}
                    </div>
                    <div className={s.heroWrapper}>
                        <div className={s.iconsWrapper}>
                            <Icons />
                        </div>
                        {/* <picture className={s.heroImage}>
                        <source srcSet={FrameWebp} type={'image/webp'} />
                        <img src={FramePng} alt={''} style={{width: '100%', height: "100%"}}/>
                    </picture>
                    <Player
                        loop
                        ref={lottiePlayerRef}
                        src={RatLottie}
                        onEvent={event => {
                            if (event === 'loop') {
                                lottiePlayerRef.current.stop();
                                setTimeout(() => {
                                    if (inView && lottiePlayerRef.current) lottiePlayerRef.current.play();
                                }, 3000);
                            }
                        }}
                        className={s.frameRat}
                        >
                    </Player>
                    <picture className={s.heroImageTwo}>
                        <source srcSet={KeyDatePeopleWebp} type={'image/webp'} />
                        <img src={KeyDatePeoplePng} alt={''} style={{width: '100%', height: "100%"}}/>
                    </picture> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Milestone;
