import React, { ReactHTMLElement } from 'react';
import { Icon } from '../../elements/icon/Icon';
// import WinnerMedal from '../../assets/svg-elements/winner-medal.svg';
// import HourGlass from '../../assets/svg-elements/hourglass.svg';
// import Clock from '../../assets/svg-elements/clock.svg';
// import Experiment from '../../assets/svg-elements/experiment.svg';
// import WinnerMedalSmall from '../../assets/svg-elements/winner-medal-small.svg';
// import HourGlassSmall from '../../assets/svg-elements/hourglass-small.svg';
// import ClockSmall from '../../assets/svg-elements/clock-small.svg';
// import ExperimentSmall from '../../assets/svg-elements/experiment-small.svg';
// import EntriesClosed from '../../assets/2023/milestone/Entries-are-now-closed.png';

// import BriefLiveIcon from '../../assets/2024/icons/Brief_live.svg';
// import EntriesOpenIcon from '../../assets/2024/icons/Entries_open.svg';
// import EntriesCloseIcon from '../../assets/2024/icons/Entries_close.svg';
// import ShortlistNotifiedIcon from '../../assets/2024/icons/Shortlist_notified.svg';
// import WinnersRevealedIcon from '../../assets/2024/icons/Winners_revealed.svg';

type IconsProps = {} & ReactHTMLElement<HTMLDivElement>['props'];

export class Icons extends React.Component<IconsProps, any> {
    state = { isDesktop: true, isMobile: false };
    updateLayout = () => {
        this.setState({
            isDesktop: window.innerWidth > 1024,
            isMobile: window.innerWidth <= 600,
            isMobileLandscape: window.innerWidth <= 900 && (window.innerHeight < window.innerWidth),
        });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateLayout);
        this.updateLayout();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateLayout);
    }
    render() {
        return (
            <div className='dates'>
                {/* <img src={EntriesClosed} className='entries-closed-image' alt='Entries now closed'/> */}
                <div className='icon-group'>
                    {/* <div>
                        <Icon className='icon-logo' icon={this.state.isDesktop? <Clock /> : <ClockSmall />} />
                    </div> */}
                    {/* <div>
                        <Icon className='icon-logo' icon={<BriefLiveIcon />} />
                    </div> */}
                    <div className='icon-text-group'>
                        <div className='icon-date'>3 FEBRUARY</div>
                        <div className='icon-description'>Brief live</div>
                    </div>
                </div>
                <div className='icon-group'>
                    {/* <div>
                        <Icon className='icon-logo-padded' icon={this.state.isDesktop? <HourGlass /> : <HourGlassSmall />} />
                    </div> */}
                    {/* <div>
                        <Icon className='icon-logo' icon={<EntriesOpenIcon />} />
                    </div> */}
                    <div className='icon-text-group'>
                        <div className='icon-date'>17 MARCH</div>
                        <div className='icon-description'>Entries open</div>
                    </div>
                </div>
                <div className='icon-group'>
                    {/* <div>
                        <Icon className='icon-logo-padded' icon={this.state.isDesktop? <HourGlass /> : <HourGlassSmall />} />
                    </div> */}
                    {/* <div>
                        <Icon className='icon-logo' icon={<EntriesCloseIcon />} />
                    </div> */}
                    <div className='icon-text-group'>
                        <div className='icon-date'>11 APRIL</div>
                        <div className='icon-description'>Entries close</div>
                    </div>
                </div>
                <div className='icon-group'>
                    {/* <div>
                        <Icon
                            className='icon-logo'
                            icon={this.state.isDesktop? <Experiment /> : <ExperimentSmall />}
                        />
                    </div> */}
                    {/* <div>
                        <Icon className='icon-logo' icon={<ShortlistNotifiedIcon />} />
                    </div> */}
                    <div className='icon-text-group'>
                        <div className='icon-date'>MAY</div>
                        <div className='icon-description'>
                            Shortlist notified
                        </div>
                    </div>
                </div>
                <div className='icon-group'>
                    {/* <div>
                        <Icon
                            className='icon-logo-padded'
                            icon={this.state.isDesktop? <WinnerMedal />:<WinnerMedalSmall /> }
                        />
                    </div> */}
                    {/* <div>
                        <Icon className='icon-logo' icon={<WinnersRevealedIcon />} />
                    </div> */}
                    <div className='icon-text-group'>
                        <div className='icon-date'>JUNE</div>
                        <div className='icon-description'>
                            Winners revealed
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
