import React, { useState, useEffect } from 'react';
import Logos from '../logos/Logos';
import s from './Brief.module.scss';
//import Login from '../login/Login';
import cn from 'classnames';
// import {Player} from '@lottiefiles/react-lottie-player';
import calcHeroAnimation from '../../helpers/calcHeroAnimation';

// import HeroDesktopWebp from "../../assets/2023/home/hero-desktop.webp";
// import HeroDesktopGif from "../../assets/2023/home/hero-desktop.gif";
// import HeroMobileWebp from "../../assets/2023/home/hero-mobile.webp";
// import HeroMobileGif from "../../assets/2023/home/hero-mobile.gif";
// import FountainWebp from "../../assets/2023/home/fountain.webp";
// import FountainPng from "../../assets/2023/home/fountain.png";

// import FountainLottie from "../../assets/2023/home/fountain-lottie.json";
// import SmokeLottie from "../../assets/2023/home/smoke-lottie.json";
// import SkiLiftLottie from "../../assets/2023/home/ski-lift-lottie.json";

interface HomePropsType {
    scrollPos: number,
    isParallax: boolean,
    inView: boolean
}

const Home = ({ inView, scrollPos, isParallax }: HomePropsType) => {
    const [heroAnimationCss, setHeroAnimationCss] = useState({});
    const heroImageRef = React.createRef<HTMLImageElement>();
    const isMobile = window.innerWidth < 601;

    // const fountainLottieRef = React.createRef();
    // const smokeLottieRef = React.createRef();
    // const skiLiftLottieRef = React.createRef();

    /* useEffect(() => {
      if (inView) {
        fountainLottieRef.current.play();
        smokeLottieRef.current.play();
        skiLiftLottieRef.current.play();
      } else {
        fountainLottieRef.current.stop();
        smokeLottieRef.current.stop();
        skiLiftLottieRef.current.stop();
      }
    }, [inView]) */

    useEffect(() => {
        if (isParallax) {
            const heroAnimStart = 0;
            const heroAnimFinish = 28 / 165; // §end of the frame - this.breakpoints[1] / this.frames
            const animationCss = calcHeroAnimation({
                scrollPos,
                heroImageRef,
                heroAnimStart,
                heroAnimFinish
            });
            setHeroAnimationCss(animationCss);
        }
    }, [scrollPos, isParallax]);

    useEffect(() => {
        if (isParallax === false) {
            setHeroAnimationCss({});
        }
    }, [isParallax]);


    // const calcHeroAnimation = (position, heroImageRef, heroAnimStart, heroAnimFinish) => {
    //     const animationHeight = 28 * 3;
    //     const fadeOut = 5;
    //     let xPos = 0;
    //     let animationCss = {};
    //     let opacity = 1;
    //
    //     if (position > heroAnimStart && position < heroAnimFinish && heroImageRef) {
    //         // Map scroll position to x position so that hero image scrolls horizontally in full
    //         // xPos is 0 when position is heroAnimStart and
    //         // xPos is (heroImageRef.current.clientWidth - window.innerWidth) when position is heroAnimFinish
    //         let interpolated = position / heroAnimFinish;
    //         let transition = -~~((interpolated * 2 - 1) * animationHeight);
    //         let delta = 0;
    //
    //         if (interpolated > 1) interpolated = 1;
    //         xPos = (heroImageRef.current.clientWidth - window.innerWidth) * interpolated;
    //
    //         // Calculate opacity just for fadeOut
    //         delta = transition + (animationHeight - fadeOut);
    //         opacity = delta < 0 ? (fadeOut - Math.abs(delta)) / fadeOut : 1;
    //     }
    //
    //     if (isParallax) {
    //         animationCss = {
    //             transform: `translate3d(-${xPos}px, 0, 0)`,
    //             opacity: opacity
    //         };
    //     }
    //     return animationCss;
    // };

    return (
        <div className={s.pageCentered}>
            {/* <div className={cn(s.mobileNavBar)}>
                <Login />
            </div> */}

            <div className={cn(s.pageFullWidth, s.pageMain)}>

                <div className={s.homeHeroWrapper}>
                    <div className={s.logosWrapper}>
                        {isMobile ? (
                            <img src='/media/2025/Mobile_Future_Lions_25_Title.svg' alt={'Future Lions Logo Mobile'} />
                        ) : (
                            <img src='/media/2025/Future_Lions_25_Title.svg' alt={'Future Lions Logo'} />
                        )}
                        <Logos pageId={0} />
                    </div>

                    {/*
                    <div className={s.briefEnter}>
                        <a
                            className={s.enterButton}
                            href='https://entries.futurelions.com/sign-in'

                        >
                            <span className={s.enterText}>
                                TAKE CENTRE STAGE: ENTER HERE
                            </span>
                        </a>
                    </div>
                    */}
                    {/* <div className={s.entriesClosed}>
                        <span className={s.entriesClosedText}>
                            ENTRIES CLOSED
                        </span>
                    </div> */}
                    {/* <h1 className={s.title}>Amplify logo lockup*</h1>
                    <h4 className={s.subtitle}>Get ready to turn it up</h4> */}
                    {/* <div className={s.homeHeroAnimate} style={heroAnimationCss}>
                        <picture className={s.homeHeroImage}>
                            <source srcSet={HeroDesktopWebp} media="(min-width: 601px)" type={'image/webp'}/>
                            <source srcSet={HeroMobileWebp} media="(max-width: 600px)" type={'image/webp'}/>
                            <source srcSet={HeroDesktopGif} type={'image/gif'}/>
                            <img src={HeroMobileGif} alt={''} ref={heroImageRef}/>
                        </picture>

                        <picture className={s.fountainImage}>
                            <source srcSet={FountainWebp} type={'image/webp'}/>
                            <img src={FountainPng} alt={''}/>
                        </picture>

                        <Player
                            ref={fountainLottieRef}
                            autoplay
                            loop
                            src={FountainLottie}
                            className={s.fountainLottie}
                        >
                        </Player>

                        <Player
                            ref={smokeLottieRef}
                            autoplay
                            loop
                            src={SmokeLottie}
                            className={s.smokeLottie}
                        >
                        </Player>

                        <Player
                            ref={skiLiftLottieRef}
                            autoplay
                            loop
                            src={SkiLiftLottie}
                            className={s.skiLiftLottie}
                        >
                        </Player>
                    </div> */}
                </div>

            </div>
        </div>
    );
};

export default Home;
