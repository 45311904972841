import React from 'react';
import s from './Brief.module.scss';
import cn from 'classnames';

interface SchoolOfYearPropTypes {
    inView: boolean
}

const SchoolOfTheYear = ({ inView }: SchoolOfYearPropTypes) => {

    return (
        <div className={s.pageCentered}>
            <div className={cn(s.pageFullWidth, s.pageSchoolOfYear)}>
                <div className={s.schoolOfYearContainer}>
                    <div className={s.descriptionWrapper}>
                        <div className={s.header}>
                            <div className={s.headerText}>TOM BEDECARRÉ SCHOOL OF THE YEAR AWARD</div>
                        </div>

                        <div className={s.descriptionText}>
                            {/* <p className={s.descriptionTitle}>
                        Tom Bedecarré School of the Year Award
                        </p> */}
                            <div className={s.descriptionContent}>
                                <p>
                                    The Tom Bedecarré School of the Year Award will recognise schools, academies and educational centres that demonstrate a proven and pioneering approach, broadening access and leveraging technology to <span className={s.nowrap}>amplify impact</span>
                                </p>
                                <p>
                                    The Tom Bedecarré School of the Year Award is in honour of AKQA’s <span className={s.nowrap}>Chairman Emeritus.</span>
                                </p>

                                <p>
                                    Written applications of no more than 500 words outlining why your organisation should be chosen can be made directly to FutureLions@akqa.com and will be reviewed by a panel of experts from the education and creative sector. The winner will be invited to receive their award from AKQA <span className={s.nowrap}>in Cannes.</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SchoolOfTheYear;
