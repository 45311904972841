import React, { ReactElement, ReactHTMLElement } from 'react';
import { Link } from 'react-router-dom';
import styles from './Logos.module.scss';
import { trackSponsors } from '../../helpers/tracking';
import CannesLogo from './../../assets/2025/logos/desktop/Cannes_Lions_Logo.svg';
import AkqaLogo from './../../assets/2025/logos/desktop/AKQA_Logo.svg';
// import SpotifyLogo from './../../assets/2025/logos/desktop/Spotify_Logo.svg';
// import WSJLogo from './../../assets/2025/logos/desktop/WSJ_Logo.svg';

import CannesLogoFooter from './../../assets/2025/logos/footer/Cannes_Lions_Logo.svg';
import AkqaLogoFooter from './../../assets/2025/logos/footer/AKQA_Logo.svg';
// import SpotifyLogoFooter from './../../assets/2025/logos/footer/Spotify_Logo.svg';
// import WSJLogoFooter from './../../assets/2025/logos/footer/WSJ_Logo.svg';

import CannesLogoMobile from './../../assets/2025/logos/mobile/Cannes_Lions_Logo.svg';
import AkqaLogoMobile from './../../assets/2025/logos/mobile/AKQA_Logo.svg';
// import SpotifyLogoMobile from './../../assets/2025/logos/mobile/Spotify_Logo.svg';
// import WSJLogoMobile from './../../assets/2025/logos/mobile/WSJ_Logo.svg';

import CannesLogoCountdown from './../../assets/2025/logos/countdown/Cannes_Lions_Logo.svg';
import AkqaLogoCountdown from './../../assets/2025/logos/countdown/AKQA_Logo.svg';

// import ForbesLogo from './../../assets/svg-elements/Forbes.svg';
// import VolvoLogo from './../../assets/svg-elements/Volvo.svg';
// import WhiteLionIcon from './../../assets/2022/main/White-FL-Logo_199x217.svg';
import { Icon } from './../../elements/icon/Icon';
import cn from 'classnames';

type IconProps = {
    icon?: ReactElement;
    pageId: number;
    breakpointsCount?: number;
    countdown?: boolean;
} & ReactHTMLElement<any>['props'];

const Logos = ({ pageId, breakpointsCount, countdown }: IconProps) => {

    const isMobile = window.innerWidth < 601;
    console.log(breakpointsCount, 'breakpointsCount')

    return (
        <>
            <div
                className={cn(styles.logos, styles.logosDark, {
                    [styles.footerLogos]: pageId === breakpointsCount - 1,
                    [styles.countdownLogos]: countdown
                })}
            >
                {/* <a
                    href='#'
                >
                    <Icon
                        className={styles.flLogo}
                        icon={ isMobile? <LionLogoMobile/> : <LionLogo/> }
                        alt='Future Lions logo'
                    />
                </a> */}
                {/* <a
                    href='https://www.spotify.com/'
                    target={'_blank'}
                    rel='noopener noreferrer'
                    onClick={(e) => trackSponsors('Spotify')}
                >
                    <Icon
                        className={styles.spotifyLogo}
                        icon={isMobile ? <SpotifyLogoMobile /> : (pageId === breakpointsCount - 1) ? <SpotifyLogoFooter /> : <SpotifyLogo />}
                        alt='Spotify logo'
                    />
                </a> */}
                {/* <a
                    href='https://www.wsj.com/'
                    target={'_blank'}
                    rel='noopener noreferrer'
                    onClick={(e) => trackSponsors('The Wall Street Journal')}
                >
                    <Icon
                        className={styles.wsjLogo}
                        icon={isMobile ? <WSJLogoMobile /> : (pageId === breakpointsCount - 1) ? <WSJLogoFooter /> : <WSJLogo />}
                        alt='The Wall Street Journal logo'
                    />
                </a> */}
                {countdown ? (
                    <>
                        <a
                            href='https://www.akqa.com/'
                            target={'_blank'}
                            onClick={(e) => trackSponsors('AKQA')}
                            rel='noopener noreferrer'
                        >
                            <Icon
                                className={styles.akqaLogo}
                                icon={<AkqaLogoCountdown />}
                                alt='Akqa logo'
                            />
                        </a>
                        <a
                            href='https://www.canneslions.com/'
                            target={'_blank'}
                            rel='noopener noreferrer'
                            onClick={(e) => trackSponsors('Cannes Lions')}
                        >
                            <Icon
                                className={styles.cannesLogo}
                                icon={<CannesLogoCountdown />}
                                alt='Cannes logo'
                            />
                        </a>
                    </>
                ) : (
                    <>
                        <a
                            href='https://www.akqa.com/'
                            target={'_blank'}
                            onClick={(e) => trackSponsors('AKQA')}
                            rel='noopener noreferrer'
                        >
                            <Icon
                                className={styles.akqaLogo}
                                icon={(pageId === breakpointsCount - 1) ? <AkqaLogoFooter /> : isMobile ? <AkqaLogoMobile /> : <AkqaLogo />}
                                alt='Akqa logo'
                            />
                        </a>
                        <a
                            href='https://www.canneslions.com/'
                            target={'_blank'}
                            rel='noopener noreferrer'
                            onClick={(e) => trackSponsors('Cannes Lions')}
                        >
                            <Icon
                                className={styles.cannesLogo}
                                icon={(pageId === breakpointsCount - 1) ? <CannesLogoFooter /> : isMobile ? <CannesLogoMobile /> : <CannesLogo />}
                                alt='Cannes logo'
                            />
                        </a>
                    </>
                )}

                {/* <a
                    href='https://www.volvo.com/'
                    target={'_blank'}
                    onClick={(e) => trackSponsors('Volvo')}
                    rel='noopener noreferrer'
                >
                    <Icon
                        className={styles.volvoLogo}
                        icon={
                            pageId !== 0 ? (
                                <VolvoLogo />
                            ) : (
                                <VolvoLogo />
                            )
                        }
                        alt='VolvoLogo logo'
                    />
                </a> */}


            </div >
            {/* {pageId === 5 &&
                <div
                    className={cn(styles.termsAndConditions, styles.logosDark)}
                >
                    <Link to={'/terms-and-conditions'}>
                        Terms and conditions
                    </Link>
                    <a href={'https://www.akqa.com/privacy-policy/'} target={'_blank'} rel='noopener noreferrer'>Privacy policy</a>
                </div>} */}
        </>
    );
};

export default Logos;
