import React, { ReactHTMLElement } from 'react';
import carousel_elements from './elements';

import Glide from '../../elements/glide';
import Swipe from '../../elements/glide/components/swipe';
import Html from '../../elements/glide/components/html';
import Translate from '../../elements/glide/components/translate';
import Transition from '../../elements/glide/components/transition';
import Direction from '../../elements/glide/components/direction';
import Breakpoints from '../../elements/glide/components/breakpoints';
import Peek from '../../elements/glide/components/peek';
import Gaps from '../../elements/glide/components/gaps';
import Move from '../../elements/glide/components/move';
import Clones from '../../elements/glide/components/clones';
import Resize from '../../elements/glide/components/resize';
import Build from '../../elements/glide/components/build';
import Run from '../../elements/glide/components/run';
import Autoplay from '../../elements/glide/components/autoplay';
import Sizes from '../../elements/glide/components/sizes';
import { Tabs } from '../tabs/Tabs';
// import {Player} from "@lottiefiles/react-lottie-player";
// import calcHeroAnimation from '../../helpers/calcHeroAnimation';
import s from "../brief/Brief.module.scss";
import cn from "classnames";

// import AlumniHeroWebp from '../../assets/2023/alumni/Alumni-hero.webp';
// import AlumniHeroPng from '../../assets/2023/alumni/Alumni-hero.png';
// import LeafLottie from "../../assets/2023/alumni/leaf-lottie.json";
// import PigeonsLottie from "../../assets/2023/alumni/pigeons-lottie.json";

type AlumniProps = { inView: boolean, scrollPos: number, isParallax: boolean } & ReactHTMLElement<HTMLDivElement>['props'];

export class Alumni extends React.Component<AlumniProps, any> {
    state = {
        heroAnimationCss: {},
        prevHeroAnimationCss: {}
    };
    carouselWrapper: HTMLElement = null;
    glideCarousel: any = null;
    leafLottieRef = React.createRef<HTMLDivElement>();
    pigeonsLottieRef = React.createRef<HTMLDivElement>();
    heroImageRef = React.createRef<HTMLImageElement>();
    yearToSlideMap = {
        '2024': 0,
        '2023': 4 + 0,
        '2022': 4 + 4,
        '2021': 4 + 8,
        '2019': 4 + 12,
        '2018': 4 + 15,
        '2017': 4 + 20,
        '2016': 4 + 25,
        '2015': 4 + 30,
        '2014': 4 + 35,
        '2013': 4 + 40,
        '2012': 4 + 45,
        '2011': 4 + 50,
        '2010': 4 + 54,
        '2009': 4 + 59,
        '2008': 4 + 63,
        '2007': 4 + 68
    };
    tabsDef = [
        { label: '2024' },
        { label: '2023' },
        { label: '2022' },
        { label: '2021' },
        { label: '2019' },
        { label: '2018' },
        { label: '2017' },
        { label: '2016' },
        { label: '2015' },
        { label: '2014' },
        { label: '2013' },
        { label: '2012' },
        { label: '2011' },
        { label: '2010' },
        { label: '2009' },
        { label: '2008' },
        { label: '2007' }
    ];

    componentDidMount() {
        this.carouselWrapper.innerHTML = carousel_elements;
        this.glideCarousel = new Glide(this.carouselWrapper, {
            type: 'carousel',
            perView: 4,
            focusAt: 0,
            startAt: 0,
            peek: 0,
            gap: 10,
            animationDuration: 800,
            swipeThreshold: 40,
            autoplay: false,
            hoverpause: false,
            dragThreshold: false,
            breakpoints: {
                1023: {
                    perView: 3,
                    peek: 0,
                    startAt: 0,
                    focusAt: 0,
                    animationDuration: 800
                },
                500: {
                    perView: 1,
                    gap: 15,
                }
            }
        });
        const mountSet = {
            Html,
            Translate,
            Transition,
            Direction,
            Peek,
            Gaps,
            Sizes,
            Move,
            Clones,
            Resize,
            Build,
            Run,
            Swipe,
            Autoplay,
            Breakpoints
        };
        this.glideCarousel.mount(mountSet);

        if (window.innerWidth <= 1024) {
            this.glideCarousel.go('=0');
            this.glideCarousel.hasBeenActive = false;
        }

        window.addEventListener('resize', this.handleWindowChange);
    }

    /* componentDidUpdate(prevProps) {
        console.log(this.props, this.state);
        if (this.props.inView !== prevProps.inView) {
            if (this.props.inView) {
                // console.log("Alumni component in view");
                this.leafLottieRef.current.play();
                this.pigeonsLottieRef.current.play();
            }
        }

        if (this.props.scrollPos !== prevProps.scrollPos && this.props.inView) {
            if (this.props.isParallax) {
                const heroAnimStart = 83 / 165; // start of component
                const heroAnimFinish = 140 / 165; // end of component
                const animationCss = calcHeroAnimation({
                    scrollPos: this.props.scrollPos,
                    heroImageRef: this.heroImageRef,
                    heroAnimStart,
                    heroAnimFinish,
                    isReverse: true
                });
                this.setState({
                    heroAnimationCss: animationCss,
                    prevHeroAnimationCss: animationCss
                });
            } else {

            }
        }
    }

    handleWindowChange = (e) => {
        if (this.props.isParallax) {
            this.setState({
                heroAnimationCss: this.state.prevHeroAnimationCss
            });
        } else {
            this.setState({
                heroAnimationCss: {}
            });
        }
    }; */

    componentWillUnmount(): void {
        this.glideCarousel.destroy();
    }

    goToYear(label: string) {
        const id = this.yearToSlideMap[label];
        this.glideCarousel.go(`=${id}`);
    }

    tabClick = (tab) => {
        this.goToYear(tab.label);
    };
    goLeft = () => {
        this.glideCarousel.go(`<`);
    };
    goRight = () => {
        this.glideCarousel.go(`>`);
    };
    checkIfDisabled = () => {
        const disabled = this.glideCarousel.disabled;
        return disabled
    }

    render() {
        return (
            <div className={s.pageCentered}>
                <div className={cn(s.pageFullWidth, s.pageAlumni)}>
                    <div className={s.alumniContainer}>
                        <div className='alumni-wrapper'>
                            <div className='alumni-header'>

                                <div className='alumni-header-text'>ALUMNI</div>
                            </div>

                            <div className='alumni-nav'>
                                <Tabs tabsDef={this.tabsDef} onClick={this.tabClick}
                                    isDisabled={this.checkIfDisabled} />
                            </div>
                            <div className='carousel-block'>
                                <div
                                    className='carousel-wrapper'
                                    ref={(ref) => (this.carouselWrapper = ref)}
                                />
                                <div className='alumni-left'>
                                    <button
                                        className='alumni-left-cta'
                                        type='button'
                                        onClick={this.goLeft}
                                    >
                                        <span className='alumni-cta-icon'></span>
                                    </button>
                                </div>
                                <div className='alumni-right'>
                                    <button
                                        className='alumni-right-cta'
                                        type='button'
                                        onClick={this.goRight}
                                    >
                                        <span className='alumni-cta-icon'></span>
                                    </button>
                                </div>
                            </div>
                            <div className='fl-link'>
                                <a href='https://www.akqa.com/future-lions/' target='_blank'>Learn more about Future Lions &#8594;</a>
                            </div>
                            {/* <div className='hero-wrapper'>
                            <div className={'hero-animate'} style={this.state.heroAnimationCss}>
                                <picture className='hero-image'>
                                    <source srcSet={AlumniHeroWebp} type={'image/webp'}/>
                                    <img src={AlumniHeroPng} alt={''} ref={this.heroImageRef} style={{width: '100%', height: "100%"}}/>
                                </picture>
                                <Player
                                    loop
                                    ref={this.pigeonsLottieRef}
                                    src={PigeonsLottie}
                                    onEvent={event => {
                                        if (event === 'loop') {
                                            this.pigeonsLottieRef.current.stop();
                                            setTimeout(() => {
                                                if (this.props.inView && this.pigeonsLottieRef.current) {
                                                    this.pigeonsLottieRef.current.play();
                                                }
                                            }, 3000);
                                        }
                                    }}
                                    className='pigeons-lottie'
                                >
                                </Player>
                                <Player
                                    loop
                                    ref={this.leafLottieRef}
                                    src={LeafLottie}
                                    onEvent={event => {
                                        if (event === 'loop') {
                                            this.leafLottieRef.current.stop();
                                            setTimeout(() => {
                                                if (this.props.inView && this.leafLottieRef.current) {
                                                    this.leafLottieRef.current.play();
                                                }
                                            }, 3000);
                                        }
                                    }}
                                    className='leaf-lottie'
                                >
                                </Player>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
