import React, { ReactHTMLElement } from 'react';
import styles from './FakeScroller.module.scss';
import cn from 'classnames';

type FakeScrollerProps = {
    height: number;
    onNormalizedScrollPositionChange?: Function;
    isMobile: boolean;
    pageId: number;
} & ReactHTMLElement<any>['props'];

export class FakeScroller extends React.Component<FakeScrollerProps, any> {
    static defaultProps = {
        height: 0
    };

    scrollerRef;

    scrollTo(top: number) {
        this.scrollerRef.scrollTo({
            top: top
            // behavior: 'smooth'
        });
    }

    onScroll(e) {
        if (this.props.onNormalizedScrollPositionChange) {
            const { scrollTop } = this.scrollerRef;
            let position = scrollTop > 0 ? scrollTop : 0;
            this.props.onNormalizedScrollPositionChange(
                position / this.props.height
            );
        }
    }

    render() {
        return (
            <div
                className={cn(styles.fakeScroller, {
                    [styles.commonBg]:
                        this.props.pageId !== 0 && !this.props.isMobile,
                    [styles.commonBgMobile]:
                        this.props.pageId !== 0 && this.props.isMobile
                })}
                ref={(ref) => (this.scrollerRef = ref)}
                onScroll={(e) => this.onScroll(e)}
            >
                {/* <div className={cn(styles.content, {
                    [styles.home]:
                        this.props.pageId === 0,
                    [styles.other]:
                        this.props.pageId !== 0 
                })}>{this.props.children}</div> */}
                <div className={cn(styles.content, styles.pages, {
                    [styles.home]:
                        this.props.pageId === 0,
                    [styles.dates]:
                        this.props.pageId === 1,
                    [styles.brief]:
                        this.props.pageId === 2,
                    [styles.soty]:
                        this.props.pageId === 3,
                    // [styles.winners]:
                    //     this.props.pageId === 3,
                    // [styles.awards]:
                    //     this.props.pageId === 4,
                    [styles.alumni]:
                        this.props.pageId === 4,
                    [styles.faq]:
                        this.props.pageId === 5,
                })}>{this.props.children}</div>
                <div
                    className={styles.filler}
                    style={{ marginBottom: this.props.height }}
                />
            </div>
        );
    }
}
